/*
--------------------------------------------------------------------------------
Open Sans, sans-serif
light: 300
semi-bold: 600
extra-bold: 800
--------------------------------------------------------------------------------
*/
@import url('https://fonts.googleapis.com/css?family=Lora:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');

@font-face {
  font-family: 'NB International Regular Webfont';
  src: url('./fonts/NB International Regular Webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/Futura.ttc');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/opensans-regular.woff') format('woff'),
       url('./fonts/opensans-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/opensans-bold.woff') format('woff'),
       url('./fonts/opensans-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/opensans-italic.woff') format('woff'),
       url('./fonts/opensans-italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/opensans-bolditalic.woff') format('woff'),
       url('./fonts/opensans-bolditalic.ttf') format('truetype');
}
