@import '_mixins.module';

/*
--------------------------------------------------------------------------------
Colors
--------------------------------------------------------------------------------
*/

.bg_medium_pink { background-color: var(--medium_pink); }
.bg_light_pink { background-color: var(--light_pink); }
.bg_light { background-color: var(--light); }

/*
--------------------------------------------------------------------------------
Text and alignments
--------------------------------------------------------------------------------
*/
.no-select   { @include no_select(); }

.truncate    { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }

.relative    { position: relative; }
.visible     { overflow: visible; }

.block       { display: block; }
.ib          { display: inline-block; }
.hide        { display: none !important; }
.fw          { width: 100%; }
.fh          { height: 100%; }
.w-25        { width: 25%; }
.w-40        { width: 40%; }
.w-50        { width: 50%; }
.w-60        { width: 60%; }
.left        { float: left; }
.right       { float: right; }
.center      { margin: auto; float: none; }
.img_center  { margin-left: 50%; transform: translateX(-50%); }
.clear:after { content: ''; clear: both; display: block; }
.t_center    { text-align: center; }
.t_left      { text-align: left; }
.t_right     { text-align: right; }
.t_justify   { text-align: justify; }
.upper       { text-transform: uppercase; }
.cammel      { text-transform: capitalize; }
.no_upper    { text-transform: none; }
.lower       { text-transform: lowercase; }
.t_shadow    { text-shadow: 0 1px 1px rgba(0,0,0, .1); }

.lh-150      { line-height: 150%; }

.va-m        { vertical-align: middle; }

/*
--------------------------------------------------------------------------------
Draggable elements
--------------------------------------------------------------------------------
*/
.drag {
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}



/*
--------------------------------------------------------------------------------
Margins
--------------------------------------------------------------------------------
*/
.mt0   { margin-top:    0; }
.mt5   { margin-top:    5px; }
.mt10  { margin-top:    10px; }
.mt15  { margin-top:    15px; }
.mt20  { margin-top:    20px; }
.mt25  { margin-top:    25px; }
.mt30  { margin-top:    30px; }
.mt35  { margin-top:    35px; }
.mt40  { margin-top:    40px; }
.mt50  { margin-top:    50px; }
.mt60  { margin-top:    60px; }
.mt75  { margin-top:    75px; }
.mt100 { margin-top:    100px; }
.mt120 { margin-top:    120px; }
.mt150 { margin-top:    150px; }
.mt200 { margin-top:    200px; }
.mt250 { margin-top:    250px; }
.mr5   { margin-right:  5px; }
.mr10  { margin-right:  10px; }
.mr15  { margin-right:  15px; }
.mr20  { margin-right:  20px; }
.mr30  { margin-right:  30px; }
.mr40  { margin-right:  40px; }
.mr50  { margin-right:  50px; }
.mr75  { margin-right:  75px; }
.mr100 { margin-right:  100px; }
.mr120 { margin-right:  120px; }
.mr150 { margin-right:  150px; }
.mr200 { margin-right:  200px; }
.mr250 { margin-right:  250px; }
.mb20 { margin-bottom: -20px; }
.mb5   { margin-bottom: 5px; }
.mb10  { margin-bottom: 10px; }
.mb15  { margin-bottom: 15px; }
.mb20  { margin-bottom: 20px; }
.mb30  { margin-bottom: 30px; }
.mb40  { margin-bottom: 40px; }
.mb50  { margin-bottom: 50px; }
.mb75  { margin-bottom: 75px; }
.mb100 { margin-bottom: 100px; }
.mb120 { margin-bottom: 120px; }
.mb150 { margin-bottom: 150px; }
.mb200 { margin-bottom: 200px; }
.mb250 { margin-bottom: 250px; }
.ml0   { margin-left:   0px; }
.ml5   { margin-left:   5px; }
.ml10  { margin-left:   10px; }
.ml15  { margin-left:   15px; }
.ml20  { margin-left:   20px; }
.ml30  { margin-left:   30px; }
.ml40  { margin-left:   40px; }
.ml50  { margin-left:   50px; }
.ml75  { margin-left:   75px; }
.ml100 { margin-left:   100px; }
.ml120 { margin-left:   120px; }
.ml150 { margin-left:   150px; }
.ml200 { margin-left:   200px; }
.ml250 { margin-left:   250px; }



/*
--------------------------------------------------------------------------------
Opacity
--------------------------------------------------------------------------------
*/
.o-10 { opacity: .1; }
.o-20 { opacity: .2; }
.o-30 { opacity: .3; }
.o-40 { opacity: .4; }
.o-50 { opacity: .5; }
.o-60 { opacity: .6; }
.o-70 { opacity: .7; }
.o-80 { opacity: .8; }
.o-90 { opacity: .9; }
