/*
--------------------------------------------------------------------------------
HTML elements look n feel
--------------------------------------------------------------------------------
*/
html {
  /* base font-size is at reset.scss */
  // @include phablet { @include retina(1.3) { font-size: 80%; } }
  // @include tablet  { @include retina(1.5) { font-size: 90%; } }
  // @include desktop { @include retina(2)   { font-size: 100%; } }
  &.fontSize100 {
    font-size: 62.5%;
  }
  &.fontSize125 {
    font-size: 75%;
  }
  &.fontSize150 {
    font-size: 90%;
  }
}

body {
  background-color: #F5F7F9;
}

