@import url(https://fonts.googleapis.com/css?family=Lora:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600);
/*
--------------------------------------------------------------------------------
Open Sans, sans-serif
light: 300
semi-bold: 600
extra-bold: 800
--------------------------------------------------------------------------------
*/
@font-face {
  font-family: 'NB International Regular Webfont';
  src: url("/static/media/NB International Regular Webfont.da904b1b.ttf") format("truetype"); }

@font-face {
  font-family: 'Futura';
  src: url(/static/media/Futura.5e638c9a.ttc); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/opensans-regular.d7e8f4bf.woff) format("woff"), url(/static/media/opensans-regular.0c24ca33.ttf) format("truetype"); }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/opensans-bold.f9e07ed1.woff) format("woff"), url(/static/media/opensans-bold.78231389.ttf) format("truetype"); }

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/static/media/opensans-italic.a34d283a.woff) format("woff"), url(/static/media/opensans-italic.701ac897.ttf) format("truetype"); }

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url(/static/media/opensans-bolditalic.e9a5c781.woff) format("woff"), url(/static/media/opensans-bolditalic.2eb4c582.ttf) format("truetype"); }

/*
--------------------------------------------------------------------------------
Sans serif auxiliar mixin
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Mobile first queries
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Grid
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Retina queries
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Fix for mobile devices
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Custom helpers
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Sans serif auxiliar mixin
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Mobile first queries
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Grid
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Retina queries
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Fix for mobile devices
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Custom helpers
--------------------------------------------------------------------------------
*/
/*
--------------------------------------------------------------------------------
Colors
--------------------------------------------------------------------------------
*/
.bg_medium_pink {
  background-color: var(--medium_pink); }

.bg_light_pink {
  background-color: var(--light_pink); }

.bg_light {
  background-color: var(--light); }

/*
--------------------------------------------------------------------------------
Text and alignments
--------------------------------------------------------------------------------
*/
.no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.truncate {
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.relative {
  position: relative; }

.visible {
  overflow: visible; }

.block {
  display: block; }

.ib {
  display: inline-block; }

.hide {
  display: none !important; }

.fw {
  width: 100%; }

.fh {
  height: 100%; }

.w-25 {
  width: 25%; }

.w-40 {
  width: 40%; }

.w-50 {
  width: 50%; }

.w-60 {
  width: 60%; }

.left {
  float: left; }

.right {
  float: right; }

.center {
  margin: auto;
  float: none; }

.img_center {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.clear:after {
  content: '';
  clear: both;
  display: block; }

.t_center {
  text-align: center; }

.t_left {
  text-align: left; }

.t_right {
  text-align: right; }

.t_justify {
  text-align: justify; }

.upper {
  text-transform: uppercase; }

.cammel {
  text-transform: capitalize; }

.no_upper {
  text-transform: none; }

.lower {
  text-transform: lowercase; }

.t_shadow {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.lh-150 {
  line-height: 150%; }

.va-m {
  vertical-align: middle; }

/*
--------------------------------------------------------------------------------
Draggable elements
--------------------------------------------------------------------------------
*/
.drag {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }
  .drag:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }

/*
--------------------------------------------------------------------------------
Margins
--------------------------------------------------------------------------------
*/
.mt0 {
  margin-top: 0; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mt25 {
  margin-top: 25px; }

.mt30 {
  margin-top: 30px; }

.mt35 {
  margin-top: 35px; }

.mt40 {
  margin-top: 40px; }

.mt50 {
  margin-top: 50px; }

.mt60 {
  margin-top: 60px; }

.mt75 {
  margin-top: 75px; }

.mt100 {
  margin-top: 100px; }

.mt120 {
  margin-top: 120px; }

.mt150 {
  margin-top: 150px; }

.mt200 {
  margin-top: 200px; }

.mt250 {
  margin-top: 250px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr15 {
  margin-right: 15px; }

.mr20 {
  margin-right: 20px; }

.mr30 {
  margin-right: 30px; }

.mr40 {
  margin-right: 40px; }

.mr50 {
  margin-right: 50px; }

.mr75 {
  margin-right: 75px; }

.mr100 {
  margin-right: 100px; }

.mr120 {
  margin-right: 120px; }

.mr150 {
  margin-right: 150px; }

.mr200 {
  margin-right: 200px; }

.mr250 {
  margin-right: 250px; }

.mb20 {
  margin-bottom: -20px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb40 {
  margin-bottom: 40px; }

.mb50 {
  margin-bottom: 50px; }

.mb75 {
  margin-bottom: 75px; }

.mb100 {
  margin-bottom: 100px; }

.mb120 {
  margin-bottom: 120px; }

.mb150 {
  margin-bottom: 150px; }

.mb200 {
  margin-bottom: 200px; }

.mb250 {
  margin-bottom: 250px; }

.ml0 {
  margin-left: 0px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 20px; }

.ml30 {
  margin-left: 30px; }

.ml40 {
  margin-left: 40px; }

.ml50 {
  margin-left: 50px; }

.ml75 {
  margin-left: 75px; }

.ml100 {
  margin-left: 100px; }

.ml120 {
  margin-left: 120px; }

.ml150 {
  margin-left: 150px; }

.ml200 {
  margin-left: 200px; }

.ml250 {
  margin-left: 250px; }

/*
--------------------------------------------------------------------------------
Opacity
--------------------------------------------------------------------------------
*/
.o-10 {
  opacity: .1; }

.o-20 {
  opacity: .2; }

.o-30 {
  opacity: .3; }

.o-40 {
  opacity: .4; }

.o-50 {
  opacity: .5; }

.o-60 {
  opacity: .6; }

.o-70 {
  opacity: .7; }

.o-80 {
  opacity: .8; }

.o-90 {
  opacity: .9; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
img, input, button, textarea,
small, strong, sub, sup, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, caption,
table, thead, tbody, tfoot, th, tr, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section, summary,
time, mark, audio, video {
  background: transparent;
  border: 0;
  border-collapse: collapse;
  border-radius: 0;
  border-spacing: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: 120%;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
  resize: none;
  vertical-align: baseline;
  text-decoration: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

input, select {
  vertical-align: middle; }

input[type=search]::-ms-clear {
  display: none;
  height: 0;
  width: 0; }

input[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0; }

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none; }

/*
--------------------------------------------------
html is set to 62.5% so that all REM measurements are 10px based
e.g. 2.7rem = 27px
--------------------------------------------------
*/
html {
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "kern";
          font-feature-settings: "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  /* Text may be inflated in this exact proportion - mobile devices */
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%; }

body {
  letter-spacing: .01em;
  line-height: 1; }

/*
--------------------------------------------------------------------------------
HTML elements look n feel
--------------------------------------------------------------------------------
*/
html {
  /* base font-size is at reset.scss */ }
  html.fontSize100 {
    font-size: 62.5%; }
  html.fontSize125 {
    font-size: 75%; }
  html.fontSize150 {
    font-size: 90%; }

body {
  background-color: #F5F7F9; }

